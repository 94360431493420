@import '~rsuite/lib/styles/themes/default/index.less';
@import './lib/rsuite/scrollbar.less';
@bgColor : #e4e5e6;

@border-radius-base: 15px;
//@body-bg: white;
@base-color: #f5c31d;

@nav-bar-inverse-bg: #f5c31d;
@nav-bar-inverse-hover-bg: @H600;
@nav-bar-inverse-active-bg: @H700;
@nav-bar-inverse-font-color: black;
@nav-bar-inverse-active-color: black;

@modal-content-bg: @bgColor;


#toast-container>div {
  opacity: 1;
}

// ------------------------------------------------------------------------------------------------

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

// ------------------------------------------------------------------------------------------------


.msc-logo {
    height: 52px;
    min-width: 30px;
    display: block;
    margin: 10px 2px 0px 2px;
}


.app-header {
    background-color: #fecb00 !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
}

.app-body {
    background-color: #f2f1ef;
}

.app-container {
    background-color: @bgColor;
    overflow: auto;
}

.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.registration-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// ------------------------------------------------------------------------------------------------
#toast-container>div {
    opacity: 1;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .badge-danger {
    color: #fff;
    background-color: #e55353;
  }

  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
    margin-left: -0px;
    margin-top: 0px;
  }

  .custom-timeline {
    margin-left: 20px;
  }
  
  .custom-timeline .rs-timeline-item-custom-dot .rs-icon {
    position: absolute;
    background: #fff;
    top: 0;
    left: -2px;
    border: 2px solid #ddd;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    padding-top: 9px;
    color: #999;
    margin-left: -13px;
  }
  
  .custom-timeline .rs-timeline-item-content {
    margin-left: 24px;
  }

  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
    max-width: 80%;
}
// ------------------------------------------------------------------------------------------------